import React from "react";
import { FaVideo, FaMapMarker } from "react-icons/fa";
import { GiWhistle } from "react-icons/gi";
import { MdOutlineDateRange, MdAccessTime } from "react-icons/md";

import ExternalLink from "./ExternalLink";
import Form from "./Form";
import { getDate, getTime } from "../util/dateUtil";
import { getMatchLink } from "../util/beinConnectUtil";
import { logos } from "../util/logos";

const Seperator = () => {
  return (
    <div className="mt-8 w-full h-2 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600"></div>
  );
};

const Note = ({ note }) => {
  return <div className="font-bold">{note}</div>;
};

const MatchInfo = ({
  matchesJson,
  homeMatchesSummary,
  awayMatchesSummary,
  children,
}) => {
  const matchDate = matchesJson.newDate || matchesJson.date;
  const backgroundClassHome = `bg-${matchesJson.home.slug}-1`;
  const backgroundClassAway = `bg-${matchesJson.away.slug}-1`;
  const textClassHome = `text-${matchesJson.home.slug}-2`;
  const textClassAway = `text-${matchesJson.away.slug}-2`;
  const iconWithTextClass = "flex gap-1 items-center mx-1 ";
  const matchTime = getTime(new Date(matchDate));
  const isMatchTimeDetermined = matchTime !== "03:00";

  return (
    <div className="w-full flex flex-col items-center">
      <div className="flex flex-col w-10/12 flex justfiy-center items-center">
        <div className="hidden md:flex px-2 text-xs w-10/12 xl:text-sm lg:w-8/12 xl:w-7/12 h-12 rounded-t-lg border-gray-300 border-2 border-b-0 text-fuchsia-900 justify-center items-center dark:text-white">
          <div className={iconWithTextClass}>
            <MdOutlineDateRange />
            {getDate(new Date(matchDate))}
          </div>

          {isMatchTimeDetermined && (
            <div className={iconWithTextClass}>
              <MdAccessTime />
              <div>{matchTime}</div>
            </div>
          )}
          {matchesJson.referee && (
            <div className={iconWithTextClass}>
              <GiWhistle />
              {matchesJson.referee}
            </div>
          )}
          <div className={iconWithTextClass}>
            <FaMapMarker />
            {matchesJson.venueName}
          </div>
          {matchesJson.status === "FINISHED" && (
            <div className="ml-2 flex items-center justify-center">
              <ExternalLink
                Icon={FaVideo}
                path={getMatchLink(matchesJson)}
                textClass="text-fuchsia-900 dark:text-white"
                marginClass=""
              />
            </div>
          )}
        </div>
        <div className="w-full h-24 flex justfiy-center">
          <div
            className={`grow basis-1/5 h-20 ${backgroundClassHome} rounded-l-lg relative`}
          >
            <div className="flex justify-end w-full z-0">
              <div className="hidden md:flex -top-2 -left-5 absolute rounded-full z-10 p-3 bg-white">
                <img
                  width="70"
                  height="70"
                  src={logos[matchesJson.home.slug]}
                  alt={matchesJson.home.fullName}
                />
              </div>
              <div className="md:hidden -left-8 absolute rounded-full p-3 bg-white">
                <img
                  width="60"
                  height="60"
                  src={logos[matchesJson.home.slug]}
                  alt={matchesJson.home.fullName}
                />
              </div>
              <div className="flex flex-col">
                <div
                  className={`${textClassHome} font-bold h-20 flex flex-col items-end justify-center text-sm md:text-md lg:text-lg mr-2 md:mr-4 lg:mr-8 2xl:mr-16`}
                >
                  <span className="sm:hidden">
                    {matchesJson.home.shortName}
                  </span>
                  <span className="hidden sm:flex">
                    {matchesJson.home.fullName}
                  </span>
                </div>
                {Boolean(homeMatchesSummary) && (
                  <div className="justify-end flex mt-3 items-center">
                    <Form
                      pastMatches={homeMatchesSummary.fivePassedMatches}
                      fullName={matchesJson.home.fullName}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="shrink-0 w-24 sm:w-1/5 h-24 lg:h-28 bg-fuchsia-900 text-white rounded-b-lg flex flex-col items-center mx-1">
            {children}
          </div>
          <div
            className={`grow basis-1/5 h-20 ${backgroundClassAway} rounded-r-lg relative`}
          >
            <div className="flex w-full">
              <div className="flex flex-col">
                <div
                  className={`${textClassAway} font-bold h-20 flex flex-col justify-center text-sm md:text-md lg:text-lg ml-2 md:ml-4 lg:ml-8 2xl:ml-16`}
                >
                  <span className="sm:hidden">
                    {matchesJson.away.shortName}
                  </span>
                  <span className="hidden sm:flex">
                    {matchesJson.away.fullName}
                  </span>
                </div>
                {Boolean(awayMatchesSummary) && (
                  <div className="flex mt-3 items-center">
                    <Form
                      pastMatches={awayMatchesSummary.fivePassedMatches}
                      fullName={matchesJson.away.fullName}
                    />
                  </div>
                )}
              </div>
              <div className="hidden md:flex -top-2 -right-5 absolute rounded-full p-3 bg-white">
                <img
                  width="70"
                  height="70"
                  src={logos[matchesJson.away.slug]}
                  alt={matchesJson.away.fullName}
                />
              </div>
              <div className="md:hidden -right-8 absolute rounded-full p-3 bg-white">
                <img
                  width="60"
                  height="60"
                  src={logos[matchesJson.away.slug]}
                  alt={matchesJson.away.fullName}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden h-full flex flex-col font-bold text-center text-xs  text-fuchsia-900 justify-center items-center dark:text-white my-4">
          <div className={iconWithTextClass}>
            <MdOutlineDateRange /> {getDate(new Date(matchDate))}
          </div>
          <div className="flex gap-1">
            <MdAccessTime />
            {getTime(new Date(matchDate)) !== "03:00" && (
              <div>{getTime(new Date(matchDate))}</div>
            )}
          </div>

          <div className={iconWithTextClass}>
            <GiWhistle />
            {matchesJson.referee}
          </div>
          <div className={iconWithTextClass}>
            <FaMapMarker />
            {matchesJson.venueName}
          </div>
          {matchesJson.status === "FINISHED" && (
            <div className="ml-3 flex items-center justify-center">
              <ExternalLink
                Icon={FaVideo}
                path={getMatchLink(matchesJson)}
                textClass="text-fuchsia-900 dark:text-white"
                marginClass=""
              />
            </div>
          )}
        </div>
      </div>
      <Seperator />
      {matchesJson.note && <Note note={matchesJson.note} />}
    </div>
  );
};

export default MatchInfo;
